import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LogoButtonComponent } from './logo-button/logo-button.component';

@Component({
  standalone: true,
  imports: [IonicModule, RouterModule, CommonModule, LogoButtonComponent],
  selector: 'app-nav',
  template: `
    <ion-header [translucent]="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button class="menu-button"> </ion-menu-button>
        </ion-buttons>
        <app-logo-button [link]="'home'"></app-logo-button>
        <ion-buttons slot="end">
          <ion-button *ngIf="showAdd" (click)="onAddClick()"
            ><ion-icon size="large" name="add"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
  `,
  styles: [
    `
      .logo {
        width: 40px;
        height: 40px;
      }

      .menu-button {
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent {
  @Input() showAdd = false;
  @Input() addRoute = 'editor/card/add';
  @Input() addLabel = 'Add';

  constructor(public router: Router) {}

  goHome() {
    this.router.navigate(['home']);
  }

  onAddClick() {
    this.router.navigate([this.addRoute]);
  }
}
