import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly BASE_URL = environment.api;

  constructor(private http: HttpClient) {}

  getEnv(): Observable<string> {
    return this.http.get<string>(`${this.BASE_URL}`);
  }
}
