import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private loading: HTMLIonLoadingElement | null = null;

  constructor(private loadingController: LoadingController) {
    this.loadingSubject
      .pipe(
        debounceTime(200), // Debounce for 200ms to handle rapid changes
        distinctUntilChanged()
      )
      .subscribe(async (isLoading) => {
        if (isLoading) {
          await this.presentLoading();
        } else {
          await this.dismissLoading();
        }
      });
  }

  show() {
    this.loadingSubject.next(true);
  }

  hide() {
    this.loadingSubject.next(false);
  }

  get isLoading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  private async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingController.create({
        message: 'Please wait...',
        spinner: 'circles',
      });
      await this.loading.present();
    }
  }

  private async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }
}
