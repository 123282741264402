import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private readonly sessionKey: string = 'app-session-date';

  constructor() {}

  /**
   * Check if user has interacted with app
   * @returns
   */
  isNewSession(): boolean {
    let lastSession = localStorage.getItem(this.sessionKey);
    if (!lastSession) {
      localStorage.setItem(this.sessionKey, new Date().toUTCString());
      return true;
    }

    const now = new Date();

    const dif = this.getHoursBetweenDates(new Date(lastSession), now);

    if (dif > 4) {
      localStorage.setItem(this.sessionKey, new Date().toUTCString());
      return true;
    }

    return false;
  }

  private getHoursBetweenDates(date1: Date, date2: Date) {
    // Ensure date1 is the earlier date
    if (date1 > date2) {
      // Swap if date1 is later than date2
      [date1, date2] = [date2, date1];
    }

    // Get the difference in milliseconds
    const diffInMilliseconds = date2.getTime() - date1.getTime();

    // Convert milliseconds to hours
    const hours = diffInMilliseconds / (1000 * 60 * 60);

    return hours;
  }
}
