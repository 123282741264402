import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Card } from '../models/card.model';
import { GenerateCourseModel } from '../models/generate-course.model';
import { GroupMaterialModel } from '../models/group-material.model';
import { Group } from '../models/group.model';
import { List } from '../models/list.model';
import { LookupModel } from '../models/lookup.model';
import { SkillTree } from '../models/skill-tree.model';
import { Upload } from '../models/upload.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private readonly BASE_URL = environment.api;

  constructor(private http: HttpClient) {}

  healthCheck() {
    return this.http.get(`${environment.api}`, { responseType: 'text' }).pipe(
      tap((m) => {
        const clientMsg = `Client: ${
          environment.production ? 'Production' : 'Development'
        }`;
        console.log(`${clientMsg}\nAPI: ${m}`);
      })
    );
  }

  getLookup = (id: string, type: string): Observable<LookupModel> =>
    this.http.get<LookupModel>(`${this.BASE_URL}/lookup/${type}/${id}`);

  getMe = (): Observable<User> =>
    this.http.get<User>(`${this.BASE_URL}/account/me`);

  getMyPermissions = (
    cacheBuster: string | null = null
  ): Observable<string[]> => {
    let append = '';
    if (!!cacheBuster) {
      append = `?cacheBuster=${cacheBuster}`;
    }
    return this.http.get<string[]>(
      `${this.BASE_URL}/account/permissions${append}`
    );
  };

  createCustomerSession = (): Observable<string> =>
    this.http
      .post<any>(
        `${this.BASE_URL}/billing/customer/create-customer-session`,
        {}
      )
      .pipe(map((m) => m.sessionId));

  createCustomerPortalSession = (): Observable<string> =>
    this.http
      .post<any>(
        `${this.BASE_URL}/billing/customer/create-customer-portal-session`,
        {}
      )
      .pipe(map((m) => m.sessionUrl));

  uploadGroupMaterialFile(
    groupId: string | null,
    file: File
  ): Observable<Upload<GroupMaterialModel>> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .post<GroupMaterialModel>(
        `${this.BASE_URL}/group/${groupId}/material`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event): Upload<GroupMaterialModel> => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round(
                (100 * event.loaded) / (event.total || 1)
              );
              return {
                file,
                progress,
                status: 'uploading',
              };
            case HttpEventType.Response:
              return {
                file,
                progress: 100,
                status: 'completed',
                response: event.body as GroupMaterialModel,
              };
            default:
              return {
                file,
                progress: 0,
                status: 'pending',
              };
          }
        })
      );
  }

  getGroupMaterials(groupId: string): Observable<GroupMaterialModel[]> {
    return this.http.get<GroupMaterialModel[]>(
      `${this.BASE_URL}/group/${groupId}/material`
    );
  }

  deleteGroupMaterial(groupId: string, materialId: string): Observable<any> {
    return this.http.delete(
      `${this.BASE_URL}/group/${groupId}/material/${materialId}`
    );
  }

  getGroupChildren(groupId: string): Observable<List<Group>> {
    return this.http.get<List<Group>>(
      `${this.BASE_URL}/group/${groupId}/children`
    );
  }

  getSkillTree(groupId: string): Observable<SkillTree> {
    return this.http.get<SkillTree>(`${this.BASE_URL}/skill-tree/${groupId}`);
  }

  getCardsByGroupId(groupId: string): Observable<List<Card>> {
    return this.http.get<List<Card>>(`${this.BASE_URL}/card/group/${groupId}`);
  }

  generateCourse = (
    generateCourseModel: GenerateCourseModel
  ): Observable<GenerateCourseModel> =>
    this.http.post<GenerateCourseModel>(
      `${this.BASE_URL}/course/generate`,
      generateCourseModel
    );

  publishCourse = (
    generateCourseModel: GenerateCourseModel
  ): Observable<string> =>
    this.http.post<string>(
      `${this.BASE_URL}/course/publish`,
      generateCourseModel
    );
}
