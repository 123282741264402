import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, filter, map } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { SessionService } from '../service/session.service';

@Injectable({
  providedIn: 'root',
})
export class SkipToAppGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private sessionService: SessionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.auth.isLoggedIn$.pipe(
      filter((isLoggedIn) => isLoggedIn !== null),
      map((isLoggedIn) => {
        if (isLoggedIn && this.sessionService.isNewSession()) {
          this.router.navigate(['home']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
