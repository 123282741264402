import { Injectable } from '@angular/core';
import { Observable, finalize, map, of, switchMap } from 'rxjs';
import { DataService } from '../api/data.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private cacheBuster: string | null = null;

  constructor(
    private dataService: DataService,
    private loadingService: LoadingService
  ) {}

  // Checks against cached permissions. If permissions don't match then refresh the cache and check again.
  hasSomePermissions(permissions: string[]): Observable<boolean> {
    return this.fetchPermissions(permissions).pipe(
      switchMap((res) => {
        if (res) {
          return of(res);
        }
        this.bustCache();
        return this.fetchPermissions(permissions);
      })
    );
  }

  private fetchPermissions = (permissions: string[]) => {
    this.loadingService.show();
    return this.dataService.getMyPermissions(this.cacheBuster).pipe(
      map((permissionList) => {
        this.loadingService.hide();
        return this.checkPermissions(permissionList, permissions);
      }),
      finalize(() => this.loadingService.hide())
    );
  };

  private checkPermissions(left: string[], right: string[]): boolean {
    return left.some((permission) => right.includes(permission));
  }

  private bustCache = () =>
    (this.cacheBuster = new Date().getTime().toString());
}
