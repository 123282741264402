import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Auth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  user,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  debounce,
  tap,
  timer,
} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth = inject(Auth);
  private readonly BASE_URL = environment.api;
  user$ = user(this.auth);
  userSubscription: Subscription;
  isLoggedIn$ = new BehaviorSubject<boolean | null>(null);
  friendlyUsername$ = new BehaviorSubject<string | null>(null);
  email$ = new BehaviorSubject<string | null>(null);

  private actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: window.location.origin + '/sign-in/validate',
    // This must be true.
    handleCodeInApp: true,
  };

  constructor(private router: Router, private http: HttpClient) {
    this.userSubscription = this.user$
      .pipe(
        debounce(() => timer(100)),
        tap((aUser: any | null) => {
          //handle user state changes here. Note, that user will be null if there is no currently logged in user.
          console.debug(aUser);
          this.isLoggedIn$.next(aUser !== null);
          this.friendlyUsername$.next(aUser?.displayName ?? aUser?.email);
          this.email$.next(aUser?.email);
        })
      )
      .subscribe();
  }

  getToken(): Promise<string | null> {
    return this.auth.authStateReady().then(() => {
      return this.auth.currentUser?.getIdToken() ?? Promise.resolve(null);
    });
  }

  signOut(): Promise<void> {
    return this.auth.signOut().then((result) => {
      window.localStorage.clear();
      setTimeout(() => {
        this.router.navigate(['']);
      }, 100);
    });
  }

  sendSignInLinkToEmail(email: string): Promise<void> {
    return sendSignInLinkToEmail(this.auth, email, this.actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorMessage);
      });
  }

  checkSignInLink(email: string | null = null): void {
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(this.auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      if (!email || email === '') {
        email = window.localStorage.getItem('emailForSignIn');
      }

      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(this.auth, email!, window.location.href)
        .then((result) => {
          // Clear email from storage.
          localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser

          // let our api know a user is created/logged in
          result.user.getIdToken().then((token) => {
            this.signInInternalUser()
              .pipe(
                tap(() => {
                  this.router.navigate(['home']);
                })
              )
              .subscribe();
          });
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }

  private signInInternalUser(): Observable<any> {
    return this.http.post(`${this.BASE_URL}/account/sign-in`, {});
  }
}
