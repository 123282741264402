// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://dev.api.kuado.io',
  firebase: {
    apiKey: 'AIzaSyCEiWO5N73BiGEvXtCZ6gy-1ZlyL6mcVbA',
    authDomain: 'development-kuado.firebaseapp.com',
    projectId: 'development-kuado',
    storageBucket: 'development-kuado.appspot.com',
    messagingSenderId: '340113702174',
    appId: '1:340113702174:web:1d9cdcec9476ee91e95d0f',
    measurementId: 'G-XRW5JHSD2C',
  },
  stripe: {
    buyButtonId: 'buy_btn_1PMGEzJ1KmrL8A4CFvCr7Fvh',
    publishableKey: 'pk_test_Ho1AHOlI0c8Td8hH0qcEFA3y',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
