import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { PermissionsGuard } from './guard/permissions.guard';
import { SkipToAppGuard } from './guard/skip-to-app.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [SkipToAppGuard],
    loadChildren: () =>
      import('./page/landing/landing.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./page/pricing/pricing.module').then((m) => m.PricingPageModule),
  },
  {
    path: 'home',
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permissions: ['basic'] },
    loadChildren: () =>
      import('./page/kuado/kuado.module').then((m) => m.KuadoPageModule),
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./page/sign-in/sign-in.module').then((m) => m.SignInPageModule),
  },
  {
    path: 'learn',
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permissions: ['basic'] },
    loadChildren: () =>
      import('./page/learn/learn.module').then((m) => m.LearnPageModule),
  },
  {
    path: 'skills',
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permissions: ['basic'] },
    loadChildren: () =>
      import('./page/skills/skills.module').then((m) => m.SkillsPageModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./page/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'class',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./page/class/class.module').then((m) => m.ClassPageModule),
  },
  {
    path: 'school',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./page/school/school.module').then((m) => m.SchoolPageModule),
  },
  {
    path: 'org',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./page/org/org.module').then((m) => m.OrgPageModule),
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
