<ion-app>
  <ion-split-pane [when]="false" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-menu-toggle
            auto-hide="false"
            *ngFor="let p of appPages; let i = index"
          >
            <ion-item
              routerDirection="root"
              [routerLink]="[p.url]"
              lines="none"
              detail="false"
              routerLinkActive="selected"
            >
              <ion-icon
                aria-hidden="true"
                slot="start"
                [ios]="p.icon + '-outline'"
                [md]="p.icon + '-sharp'"
              ></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <!--
        <ion-list id="labels-list">
          <ion-list-header>Labels</ion-list-header>

          <ion-item *ngFor="let label of labels" lines="none">
            <ion-icon aria-hidden="true" slot="start" ios="bookmark-outline" md="bookmark-sharp"></ion-icon>
            <ion-label>{{ label }}</ion-label>
          </ion-item>
        </ion-list>
        -->
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
